import React, { useState } from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";
import ValueProps from "../components/Repeating/ValueProps";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import ButtonGhost from "../components/Button/ButtonGhost";
import ButtonSolid from "../components/Button/ButtonSolid";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import CTA from "../components/Repeating/CTA";

const StyledContent = styled.div`
  ul {
    ${tw`font-body list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`font-body text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }

  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  const [parallaxDisabled, setParallaxDisabled] = useState(false);

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Hotel & Hospitality Staffing Services | HCS"
        description="We offer a full scope of hotel & hospitality staffing services nationwide to ensure you have the workforce you need. Get five-star assistance today!"
        hide={true}
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-28 md:mb-32 overflow-x-hidden md:overflow-hidden">
        <div className="relative md:static mr-14 md:mr-0">
          <div className="md:absolute md:right-0">
            <GatsbyImage
              image={data.hero.childImageSharp.gatsbyImageData}
              className="md:h-full  md:w-[60vw] ml-4 -mr-16"
            />
          </div>
          <div className="border border-primary-600 max-w-[585px] max-h-[576px] w-full h-[92.5%] absolute top-14 left-0 md:hidden"></div>
        </div>
        <div className="container relative">
          <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-18 items-center md:h-[704px]">
            <header className="md:col-start-1 md:col-span-11 md:pt-36 z-10">
              <GatsbyImage
                image={data.heroLogo.childImageSharp.gatsbyImageData}
                className="md:left-10 lg:left-10 mb-5 -top-12 md:top-0"
              />
              <h1 className="relative md:left-10 lg:left-10 max-w-xl -top-12 md:top-0 -mt-2 md:mt-0 mb-0 md:mb-6">
                MARRIOTT’S PREFERRED HOSPITALITY STAFFING PROVIDER
              </h1>
              <div className="md:max-w-sm">
                <p className="mb-0 text-sm md:text-base">
                  Deliver exceptional guest experiences with help from Hotel
                  Cleaning Services. We'll find and screen employees who
                  precisely match your criteria and expectations. As Marriott's
                  preferred hospitality staffing provider, we can assist you
                  with filling gaps during seasonal fluctuations and employee
                  turnover.
                </p>
              </div>
            </header>

            <div className="border border-primary-600 w-[708px] h-[588px] absolute top-36 right-24 md:col-end-13 md:col-span-1 hidden md:block"></div>
          </div>
        </div>
      </section>
      <section className="mb-24 md:mb-32">
        <div className="container flex flex-col items-center">
          <div className="font-heading mx-auto mb-5 text-center text-xl">
            LET US FIND QUALIFIED CANDIDATES FOR YOU. CONTACT US TODAY TO <br />
            START BENEFITING FROM OUR FLEXIBLE STAFFING SOLUTIONS.
          </div>
          <div className="flex flex-col md:flex-row gap-y-4 gap-x-4">
            <ButtonSolid
              modal="modal-contact"
              alternative={true}
              text="Get Started"
              className="md:min-w-[176px]"
            />
            <ButtonGhost
              href="/about"
              text="About us"
              altStyle={true}
              hasIcon={false}
              className="md:min-w-[176px]"
            />
          </div>
        </div>
      </section>
      <section className="mb-24 md:mb-64">
        <div className="container">
          <div className="grid md:grid-cols-12 gap-y-6 md:gap-x-10 lg:gap-x-18 items-center">
            <div className="relative md:col-start-1 md:col-span-7 mr-8 md:mr-0">
              <ParallaxProvider>
                <Parallax y={[25, -15]} disabled={parallaxDisabled}>
                  <GatsbyImage
                    image={data.intro.childImageSharp.gatsbyImageData}
                    width="606"
                  />
                </Parallax>
              </ParallaxProvider>
              <div className="border border-primary-600 max-w-[585px] max-h-[576px] w-full h-full absolute top-10 md:top-18 left-10 md:left-26"></div>
            </div>

            <header className="md:col-end-13 md:col-span-5">
              <h1 className="relative text-4xl md:text-6xl md:-left-24">
                POSITIONS WE STAFF
              </h1>
              <p className="text-sm md:text-base mb-0 ml-6 md:ml-0">
                You can count on us for the hotel and hospitality staff you need
                to operate a successful business. Your goal is to deliver an
                exceptional guest experience, and so is ours. Our contract
                staffing includes:
              </p>
              <ul className="ml-6 lg:ml-0 list-disc list-inside pt-5 tracking-wider">
                <li>Banquet Houseman</li>
                <li>Culinary and Cook Positions</li>
                <li>Day Porters</li>
                <li>Housekeeping Staff</li>
                <li>Housekeeping Supervisors</li>
                <li>Houseman</li>
                <li>Kitchen Stewards</li>
                <li>Landscaping Staff</li>
                <li>Room Attendants</li>
              </ul>
            </header>
          </div>
        </div>
      </section>
      <section className="mb-24 md:mb-32">
        <div className="container flex flex-col md:flex-row gap-x-18 ">
          <h2 className="lg:min-w-[429px]">
            THE BENEFITS OF PARTNERING WITH US
          </h2>
          <div>
            <div id="anchor-1" className="mb-14 lg:mb-16">
              <h3 className="capitalize">Free Up Human Resources Time</h3>
              <p>
                One of the benefits of utilizing hotel and hospitality staffing
                services is that we take the time to find, interview, and screen
                candidates. This can help free up your human resources
                department to focus on other areas.
              </p>
            </div>

            <div id="anchor-2" className="mb-14 lg:mb-16">
              <h3 className="capitalize">Locate Skilled Candidates</h3>
              <p>
                We find employees who precisely match your criteria and
                expectations. During the screening process, we test
                proficiencies related to the position to ensure a good match.
                This reduces training time and can help increase overall
                satisfaction with your employees.
              </p>
            </div>

            <div id="anchor-3">
              <h3 className="capitalize">
                Manage Seasonal Fluctuations & Employee Turnover
              </h3>
              <p>
                We see to it that you have the staffing you need when you need
                it. From big events to busy times of the year, we can provide
                temporary staff to fulfill your demand. If you’ve experienced
                high staff turnover, let us fill the gaps with long-term,
                qualified employees.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-24 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-12 gap-y-6 md:gap-x-10 lg:gap-x-18 items-center">
            <div className="relative md:col-start-1 md:col-span-7 mr-8 md:mr-0">
              <ParallaxProvider>
                <Parallax y={[25, -15]} disabled={parallaxDisabled}>
                  <GatsbyImage
                    image={data.heroDesktop.childImageSharp.gatsbyImageData}
                    width="606"
                  />
                </Parallax>
              </ParallaxProvider>
              <div className="border border-primary-600 max-w-[585px] max-h-[576px] w-full h-full absolute top-10 md:top-18 left-10 md:left-26"></div>
            </div>

            <header className="md:col-end-13 md:col-span-5">
              <h1 className="relative md:-left-24 text-4xl md:text-6xl">
                Locations we serve
              </h1>
              <div className="grid grid-cols-2">
                <ul className="list-disc list-inside pt-5 tracking-wider">
                  <li>Aspen</li>
                  <li>Dallas-Fort Worth</li>
                  <li>Denver</li>
                  <li>Los Angeles</li>
                  <li>Miami</li>
                  <li>Minneapolis</li>
                  <li>Nashville </li>
                  <li>Oklahoma City </li>
                  <li>Orlando</li>
                </ul>
                <ul className="list-disc list-inside pt-5 tracking-wider">
                  <li>Palm Springs </li>
                  <li>Park City </li>
                  <li>Phoenix</li>
                  <li>Salt Lake City </li>
                  <li>San Diego</li>
                  <li>Savannah</li>
                  <li>Tampa</li>
                  <li>Tucson</li>
                </ul>
              </div>
            </header>
          </div>
        </div>
      </section>
      <ValueProps />
      <Testimonials />
      <CallToAction
        heading={[
          "Let’s Become ",
          <span className="text-primary-500">Partners</span>,
        ]}
        subtext="From short-term help to long-term staff members for your hotel, restaurant, or other establishments, we can get you the staffing you need. Contact us today!"
        hideNumber={true}
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Hotel Staffing.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Hotel Staffing.jpg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "talent-point/1.0 Marriott Hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 817)
      }
    }
    heroLogo: file(
      relativePath: { eq: "talent-point/marriot-talent-point-logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    intro: file(relativePath: { eq: "talent-point/2.0 Marriott intro.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 606)
      }
    }
    heroDesktop: file(
      relativePath: { eq: "talent-point/2.0 home intro desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 606)
      }
    }
    heroMobile: file(
      relativePath: { eq: "services/2.3 Staffing/1.0 Staffing Hero mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
